import kebabCase from 'lodash/kebabCase';
import invariant from 'tiny-invariant';
import URLBuilder from '@/lib/url-builder';

export default function contentCatalogueUrl(
  categoryHash: string,
  categoryName?: string,
  catalogueOrderBy?: string,
  catalogueFilter?: string,
  urlQuerySearchParams?: URLSearchParams | null | undefined,
  urlHash?: string | null | undefined,
): string {
  invariant(typeof categoryHash === 'string', `'contentCatalogueUrl' requires 'categoryHash' parameter to be defined.`);
  const encodedName = kebabCase(categoryName ?? 'katalog');
  const encodedOrderBy = catalogueOrderBy ?? 'o0';
  const encodedFilter = catalogueFilter == null ? '' : `/${catalogueFilter}`;

  const url = new URLBuilder(
    `/${encodedName}/c/${categoryHash}/${encodedOrderBy}${encodedFilter}`,
    urlQuerySearchParams,
    urlHash,
  );
  return url.build();
}
