import Image from 'next/image';
import Link from 'next/link';
import noProductImage_350x350 from './assets/no-product-image_350x350.webp';
import type { ImageLoader } from 'next/image';
import type { ComponentProps, ElementType, ReactNode } from 'react';

// *****************************************
// *
// * ProductCardRoot
// *
// *****************************************

const ProductCardRootDefaultElement: ElementType = 'article';

interface ProductCardRoot {
  <E extends ElementType = typeof ProductCardRootDefaultElement>(props: ProductCardRootProps<E>): JSX.Element;
  displayName: string;
}

type ProductCardRootProps<E extends ElementType> = ProductCardRootOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardRootOwnProps>;

type ProductCardRootOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
  href: string;
  prefetch?: boolean;
};

const ProductCardRoot: ProductCardRoot = ({
  as: Component = ProductCardRootDefaultElement,
  children,
  className,
  href,
  prefetch,
  ...rest
}) => {
  return (
    <Component className={className} {...rest}>
      {children}
      <Link href={href} prefetch={prefetch}>
        <span className='absolute inset-0' />
      </Link>
    </Component>
  );
};

ProductCardRoot.displayName = 'ProductCard';

// *****************************************
// *
// * ProductCardImage
// *
// *****************************************

const ProductCardImageDefaultElement: ElementType = 'div';

interface ProductCardImage {
  <E extends ElementType = typeof ProductCardImageDefaultElement>(props: ProductCardImageProps<E>): JSX.Element;
  displayName: string;
}

type ProductCardImageProps<E extends ElementType> = ProductCardImageOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardImageOwnProps>;

type ProductCardImageOwnProps<E extends ElementType = ElementType> = {
  alt: string;
  as?: E;
  className?: string;
  height: number | null | undefined;
  imageClassName?: string;
  loader?: ImageLoader;
  src: string | null | undefined;
  unoptimized?: boolean;
  width: number | null | undefined;
};

const ProductCardImage: ProductCardImage = ({
  alt,
  as: Component = ProductCardImageDefaultElement,
  className,
  height,
  imageClassName,
  loader,
  src,
  unoptimized,
  width,
  ...rest
}) => {
  return (
    <Component className={className} {...rest}>
      {src == null ? (
        <Image alt='Image not found' className={imageClassName} height={350} src={noProductImage_350x350} width={350} />
      ) : (
        <Image
          alt={alt}
          className={imageClassName}
          height={height ?? undefined}
          loader={loader}
          src={src}
          unoptimized={unoptimized}
          width={width ?? undefined}
        />
      )}
    </Component>
  );
};

ProductCardImage.displayName = 'ProductCard_Image';

// *****************************************
// *
// * ProductCardPanel
// *
// *****************************************

const ProductCardPanelDefaultElemet: ElementType = 'div';

interface ProductCardPanel {
  <E extends ElementType = typeof ProductCardPanelDefaultElemet>(props: ProductCardPanelProps<E>): JSX.Element;
  displayName: string;
}

type ProductCardPanelProps<E extends ElementType> = ProductCardPanelOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardPanelOwnProps>;

type ProductCardPanelOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
};

const ProductCardPanel: ProductCardPanel = ({ as: Component = ProductCardPanelDefaultElemet, children, ...rest }) => (
  <Component {...rest}>{children}</Component>
);

ProductCardPanel.displayName = 'ProductCard_Panel';

// *****************************************
// *
// * ProductCardHeading
// *
// *****************************************

const ProductCardHeadingDefaultElement: ElementType = 'h1';

interface ProductCardHeading {
  <E extends ElementType = typeof ProductCardHeadingDefaultElement>(props: ProductCardHeadingProps<E>): JSX.Element;
  displayName: string;
}

type ProductCardHeadingProps<E extends ElementType> = ProductCardHeadingOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardHeadingOwnProps>;

type ProductCardHeadingOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
};

const ProductCardHeading: ProductCardHeading = ({
  as: Component = ProductCardHeadingDefaultElement,
  children,
  ...rest
}) => <Component {...rest}>{children}</Component>;

ProductCardHeading.displayName = 'ProductCard_Heading';

// *****************************************
// *
// * ProductCardDescription
// *
// *****************************************

const ProductCardDescriptionDefaultElement: ElementType = 'p';

interface ProductCardDescription {
  <E extends ElementType = typeof ProductCardDescriptionDefaultElement>(
    props: ProductCardDescriptionProps<E>,
  ): JSX.Element;
  displayName: string;
}

type ProductCardDescriptionProps<E extends ElementType> = ProductCardDescriptionOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardDescriptionOwnProps>;

type ProductCardDescriptionOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
};

const ProductCardDescription: ProductCardDescription = ({
  as: Component = ProductCardDescriptionDefaultElement,
  children,
  ...rest
}) => <Component {...rest}>{children}</Component>;

ProductCardDescription.displayName = 'ProductCard_Description';

// *****************************************
// *
// * ProductCardActions
// *
// *****************************************

const ProductCardActionsDefaultElement: ElementType = 'div';

interface ProductCardActions {
  <E extends ElementType = typeof ProductCardActionsDefaultElement>(props: ProductCardActionsProps<E>): JSX.Element;
  displayName: string;
}

type ProductCardActionsProps<E extends ElementType> = ProductCardActionsOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardActionsOwnProps>;

type ProductCardActionsOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
};

const ProductCardActions: ProductCardActions = ({
  as: Component = ProductCardActionsDefaultElement,
  children,
  ...rest
}) => <Component {...rest}>{children}</Component>;

ProductCardActions.displayName = 'ProductCard_Actions';

// *****************************************
// *
// * ProductCardPrice
// *
// *****************************************

const ProductCardPriceDefaultElement: ElementType = 'div';

interface ProductCardPrice {
  <E extends ElementType = typeof ProductCardPriceDefaultElement>(props: ProductCardPriceProps<E>): JSX.Element;
  displayName: string;
}

type ProductCardPriceProps<E extends ElementType> = ProductCardPriceOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardPriceOwnProps>;

type ProductCardPriceOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
};

const ProductCardPrice: ProductCardPrice = ({ as: Component = ProductCardPriceDefaultElement, children, ...rest }) => (
  <Component {...rest}>{children}</Component>
);

ProductCardPrice.displayName = 'ProductCard_Price';

// *****************************************
// *
// * ProductCardColors
// *
// *****************************************

const ProductCardColorsDefaultElement: ElementType = 'ul';

interface ProductCardColors {
  <E extends ElementType = typeof ProductCardColorsDefaultElement>(
    props: ProductCardColorsProps<E>,
  ): JSX.Element | null;
  displayName: string;
}

type ProductCardColorsProps<E extends ElementType> = ProductCardColorsOwnProps<E> &
  Omit<ComponentProps<E>, keyof ProductCardColorsOwnProps>;

type ProductCardColorsOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  className?: string;
  heading: string;
  headingElement?: ElementType;
  itemClassName?: string;
  itemElement?: ElementType;
  items: { color: string; name: string | null | undefined }[];
};

const ProductCardColors: ProductCardColors = ({
  as: Component = ProductCardColorsDefaultElement,
  className,
  heading,
  headingElement: Heading = 'h2',
  itemClassName,
  itemElement: Item = 'li',
  items,
  ...rest
}) => {
  if (items == null || items.length === 0) return null;

  return (
    <>
      <Heading className='sr-only'>{heading}</Heading>
      <Component className={className} {...rest}>
        {items.map((item) => (
          <Item key={item.name ?? item.color} className={itemClassName} style={{ backgroundColor: item.color }}>
            <span className='sr-only'>{item.name}</span>
          </Item>
        ))}
      </Component>
    </>
  );
};

ProductCardColors.displayName = 'ProductCard_Colors';

// *****************************************
// *
// * ProductCard
// *
// *****************************************

const ProductCard = Object.assign(ProductCardRoot, {
  Actions: ProductCardActions,
  Colors: ProductCardColors,
  Description: ProductCardDescription,
  Heading: ProductCardHeading,
  Image: ProductCardImage,
  Panel: ProductCardPanel,
  Price: ProductCardPrice,
});

export default ProductCard;
