import { Bars3Icon, MagnifyingGlassIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import contentHomeUrl from '@/content/content-home-url';
import contentRegistrationUrl from '@/content/content-registration-url';
import LayoutBaseHeaderSearchDialog from './layout-base-header-search-dialog';
import { useMobileNavigationOpenHandle } from './layout-context';

export default function LayoutBaseHeaderNavigationTools() {
  const intl = useIntl();
  const setMobileMenuOpen = useMobileNavigationOpenHandle();
  const [isOpen, setIsOpen] = useState(false);
  const openSearchModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeSearchModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className='bg-surface'>
      <div className='border-surface-divid border-b lg:border-b-0'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='flex h-16 items-center justify-between'>
            {/* Logo (lg+) */}
            <div className='hidden lg:flex lg:items-center'>
              <Link href={contentHomeUrl()} prefetch={false}>
                <span className='sr-only'>{'Blyth'}</span>
                <Image
                  alt={'Blyth'}
                  className='h-8 w-auto'
                  height={50}
                  src='/images/logo/logo_200x50.webp'
                  width={200}
                />
              </Link>
            </div>

            {/* Sarch tool */}
            <div className='hidden h-full px-8 print:hidden lg:flex lg:justify-center'>
              <div className='flex items-center justify-center'>
                <button
                  className='md:ring-surface-suspend md:hover:ring-surface-focus md:hover:bg-surface-suspend group flex h-6 w-6 items-center justify-center sm:justify-start md:h-auto md:w-80 md:flex-none md:rounded-lg md:py-2.5 md:pl-4 md:pr-3.5 md:text-sm md:ring-1 lg:w-96'
                  onClick={openSearchModal}
                  type='button'
                >
                  <MagnifyingGlassIcon className='h-5 w-5 flex-none' />
                  <span className='md:text-content md:dark:text-content-suspend sr-only md:not-sr-only md:ml-2'>
                    {'Vyhledat produkty'}
                  </span>
                  {/* <kbd className='text-content-suspend dark:text-content-suspend ml-auto hidden font-medium md:block'>
                    <kbd className='font-sans'>⌘</kbd>
                    <kbd className='font-sans'>K</kbd>
                  </kbd> */}
                </button>
              </div>
            </div>

            {/* Mobile menu and search (lg-) */}
            <div className='flex flex-1 items-center print:hidden lg:hidden'>
              <button
                className='bg-surface text-content hover:text-content-focus -ml-2 rounded-md p-2'
                onClick={() => setMobileMenuOpen(true)}
                type='button'
              >
                <span className='sr-only'>
                  {intl.formatMessage({
                    defaultMessage: 'Otevřít mobilní navigaci',
                    description: 'Open mobile menu action label.',
                    id: 'v0tv/r',
                  })}
                </span>
                <Bars3Icon aria-hidden='true' className='h-6 w-6' />
              </button>

              {/* Search */}
              <button className='text-content hover:text-content-focus ml-2 p-2' onClick={openSearchModal}>
                <span className='sr-only'>Search</span>
                <MagnifyingGlassIcon aria-hidden='true' className='h-6 w-6' />
              </button>
            </div>

            {/* Logo (lg-) */}
            <Link
              className='print:flex print:w-full print:justify-center lg:hidden'
              href={contentHomeUrl()}
              prefetch={false}
            >
              <span className='sr-only'>{'Blyth'}</span>
              <Image alt='Blyth' className='h-8 w-auto' height={50} src='/images/logo/logo_200x50.webp' width={200} />
            </Link>

            <div className='flex flex-1 items-center justify-end lg:flex-none'>
              <div className='flex items-center lg:ml-8'>
                <div className='flex space-x-8'>
                  {/* <div className='hidden lg:flex'>
                    <button className='text-content hover:text-content-focus -m-2 p-2' onClick={openSearchModal}>
                      <span className='sr-only'>Search</span>
                      <MagnifyingGlassIcon aria-hidden='true' className='h-6 w-6' />
                    </button>
                  </div> */}

                  <div className='flex print:hidden'>
                    {/* <button className='text-content hover:text-content-focus -m-2 p-2'>
                      <span className='sr-only'>Account</span>
                      <UserIcon aria-hidden='true' className='h-6 w-6' />
                    </button> */}
                    <Link className='btn btn-sm btn-accent' href={contentRegistrationUrl()}>
                      <span className='sr-only sm:not-sr-only'>{'Registrace'}</span>
                      <UserGroupIcon aria-hidden='true' className='h-6 w-6 sm:hidden' />
                    </Link>
                  </div>
                </div>

                {/*
                <span aria-hidden='true' className='bg-surface-divid mx-4 h-6 w-px lg:mx-6' />

                <div className='flow-root'>
                  <button className='group -m-2 flex items-center p-2'>
                    <ShoppingCartIcon
                      aria-hidden='true'
                      className='text-content group-hover:text-content-focus h-6 w-6 shrink-0'
                    />
                    <span className='text-content-focus group-hover:text-conetent-focus ml-2 text-sm font-medium'>
                      0
                    </span>
                    <span className='sr-only'>items in cart, view bag</span>
                  </button>
                </div>
                */}
              </div>
            </div>

            {/* End of game */}
            <LayoutBaseHeaderSearchDialog closeModal={closeSearchModal} isOpen={isOpen} />
          </div>
        </div>
      </div>
    </div>
  );
}
