import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { Fragment } from 'react';
import contentAboutUsUrl from '@/content/content-about-us-url';
import contentCatalogueUrl from '@/content/content-catalogue-url';
import contentContactsUrl from '@/content/content-contacts-url';
import { useLayoutData, useMobileNavigationState } from './layout-context';

// const navigation = {
//   pages: [
//     { href: '#', name: 'Company' },
//     { href: '#', name: 'Stores' },
//   ],
// };

export default function LayoutBaseNavigationMobile() {
  const { categoriesTree } = useLayoutData();
  const [mobileMenuOpen, setMobileMenuOpen] = useMobileNavigationState();

  return (
    <Transition.Root as={Fragment} show={mobileMenuOpen}>
      <Dialog as='div' className='relative z-40 lg:hidden' onClose={setMobileMenuOpen}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/25' />
        </Transition.Child>

        <div className='fixed inset-0 z-40 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <Dialog.Panel className='relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl'>
              <div className='flex px-4 pt-5 pb-2'>
                <button
                  className='-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400'
                  onClick={() => setMobileMenuOpen(false)}
                  type='button'
                >
                  <span className='sr-only'>Close menu</span>
                  <XMarkIcon aria-hidden='true' className='h-6 w-6' />
                </button>
              </div>

              <div className='border-surface-divid space-y-6 border-t py-6 px-4'>
                {categoriesTree.map((category1) => (
                  <div key={category1.hash} className='flow-root'>
                    <Link
                      className='text-content -m-2 block p-2 font-medium'
                      href={contentCatalogueUrl(category1.hash, category1.name)}
                      onClick={() => {
                        setMobileMenuOpen(false);
                      }}
                      prefetch={false}
                    >
                      {category1.name}
                    </Link>
                  </div>
                ))}
              </div>

              <div className='border-surface-divid space-y-6 border-t py-6 px-4'>
                <div className='flow-root'>
                  <Link
                    className='text-content -m-2 block p-2 font-medium'
                    href={contentAboutUsUrl()}
                    onClick={() => {
                      setMobileMenuOpen(false);
                    }}
                    prefetch={false}
                  >
                    {'O nás'}
                  </Link>
                </div>
                <div className='flow-root'>
                  <Link
                    className='text-content -m-2 block p-2 font-medium'
                    href={contentContactsUrl()}
                    onClick={() => {
                      setMobileMenuOpen(false);
                    }}
                    prefetch={false}
                  >
                    {'Kontakty'}
                  </Link>
                </div>
                <div className='flow-root'>
                  <Link
                    className='text-secondary -m-2 block p-2 font-medium'
                    href={'https://www.bunzl.com/'}
                    prefetch={false}
                    target={'_blank'}
                  >
                    {'BUNZL Green'}
                  </Link>
                </div>
                <div className='flow-root'>
                  <Link
                    className='text-content -m-2 block p-2 font-medium'
                    href={'https://b2b.blyth.cz/prihlaseni'}
                    prefetch={false}
                    target={'_blank'}
                  >
                    {'B2B eshop'}
                  </Link>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
