import LayoutBaseFooter from './layout-base-footer';
import LayoutBaseHeaderNavigationMain from './layout-base-header-navigation-main';
import LayoutBaseHeaderNavigationTools from './layout-base-header-navigation-tools';
import LayoutBaseHeaderNavigationTopStrip from './layout-base-header-navigation-top-strip';
import LayoutBaseNavigationMobile from './layout-base-navigation-mobile';
import type { ReactNode } from 'react';

// *****************************************
// *
// * LayoutBase
// *
// *****************************************

export type LayoutBaseProps = {
  children?: ReactNode;
};

export default function LayoutBase({ children = null }: LayoutBaseProps) {
  return (
    <>
      <LayoutBaseNavigationMobile />
      <header className='relative z-10'>
        <nav aria-label='Top'>
          <LayoutBaseHeaderNavigationTopStrip />
          <LayoutBaseHeaderNavigationTools />
          <LayoutBaseHeaderNavigationMain />
        </nav>
      </header>

      {children}

      {/* <ProductComparisonWidget /> */}
      <LayoutBaseFooter />

      {/* Layout level dialogs */}
      {/* <LayoutBaseLoginDialog /> */}
    </>
  );
}
