import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import contentCatalogueUrl from '@/content/content-catalogue-url';
import classNames from '@/lib/class-names';
import { useLayoutData } from './layout-context';

export default function LayoutBaseHeaderNavigationMain() {
  return (
    <div className='bg-surface hidden print:hidden lg:block'>
      <div className='border-surface-divid border-b'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='flex h-12 items-center justify-between'>
            {/* Mega menus */}
            <LayoutBaseHeaderNavigationMainMegaMenu />
            {/* End of game */}
          </div>
        </div>
      </div>
    </div>
  );
}

// *****************************************
// *
// * LayoutBaseHeaderNavigationMainMegaMenu
// *
// *****************************************

function LayoutBaseHeaderNavigationMainMegaMenu() {
  const intl = useIntl();
  const { categoriesTree } = useLayoutData();
  return (
    <div className='hidden h-full lg:flex'>
      <Popover.Group className='ml-8 first:ml-0'>
        <div className='flex h-full justify-center space-x-8'>
          {categoriesTree.map((category1) =>
            category1.subItems == null ? (
              <div key={category1.hash} className='flex items-center text-sm'>
                <Link
                  className='text-content hover:text-content border-transparent font-medium'
                  href={contentCatalogueUrl(category1.hash, category1.name)}
                  prefetch={false}
                >
                  {category1.name}
                </Link>
              </div>
            ) : (
              <Popover key={category1.hash} className='flex'>
                {({ close: closePopover, open }) => (
                  <>
                    <div className='relative flex'>
                      <Popover.Button
                        className={classNames(
                          open ? 'border-mask text-mask' : 'text-content hover:text-content border-transparent',
                          'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium outline-0 transition-colors duration-200 ease-out',
                        )}
                      >
                        {category1.name}
                      </Popover.Button>
                    </div>

                    <Transition.Child
                      as={Fragment}
                      enter='ease-out duration-150'
                      enterFrom='opacity-0'
                      enterTo='opacity-100'
                      leave='ease-in duration-100'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      {/* <Popover.Overlay className='fixed inset-0 top-40 bg-white/25 backdrop-blur-sm' /> */}
                      <div className='fixed inset-0 top-44 bg-white/25 backdrop-blur-sm' />
                    </Transition.Child>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0'
                      enterTo='opacity-100'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <Popover.Panel className='text-content absolute inset-x-0 top-full sm:text-sm'>
                        {({ open }) => {
                          // Prevent document content scroll when any of navigation popovers is opened.
                          if (typeof window !== 'undefined') {
                            window.document.documentElement.style.overflow = open ? 'hidden' : '';
                          }
                          return (
                            <>
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div aria-hidden='true' className='bg-surface absolute inset-0 top-1/2 shadow' />

                              <div className='bg-surface relative'>
                                <div className='mx-auto max-w-7xl px-8'>
                                  <p className='text-content pt-8'>
                                    <span>
                                      {intl.formatMessage({
                                        defaultMessage: 'Zobrazit produkty kategorie:',
                                        description: 'aaaa',
                                        id: '1hMPwI',
                                      })}
                                    </span>
                                    <span className='pl-4'>
                                      <Link
                                        className='text-primary hover:text-primary-focus font-semibold'
                                        href={contentCatalogueUrl(category1.hash, category1.name)}
                                        onClick={() => {
                                          closePopover();
                                        }}
                                        prefetch={false}
                                      >
                                        {category1.name}
                                      </Link>
                                    </span>
                                  </p>
                                  <div className='grid grid-cols-1 items-start gap-y-10 gap-x-8 pt-8 pb-12'>
                                    <div className='grid grid-cols-4 gap-y-10 gap-x-8'>
                                      {category1.subItems &&
                                        category1.subItems.map((category2) => (
                                          <div key={`category-sub-item-${category2.hash}`}>
                                            <p
                                              className='text-content-focus font-semibold'
                                              id={`desktop-featured-heading-${category2.hash}`}
                                            >
                                              <Link
                                                href={contentCatalogueUrl(category2.hash, category2.name)}
                                                onClick={() => {
                                                  closePopover();
                                                }}
                                              >
                                                {category2.name}
                                              </Link>
                                            </p>
                                            {category2.subItems && (
                                              <ul
                                                aria-labelledby={`desktop-featured-heading-${category2.hash}`}
                                                className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'
                                              >
                                                {category2.subItems.map((category3) => (
                                                  <li key={category3.hash} className='flex pl-4'>
                                                    <Link
                                                      className='hover:text-content-focus'
                                                      href={contentCatalogueUrl(category3.hash, category3.name)}
                                                      onClick={() => {
                                                        closePopover();
                                                      }}
                                                      prefetch={false}
                                                    >
                                                      {category3.name}
                                                    </Link>
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            ),
          )}
        </div>
      </Popover.Group>
    </div>
  );
}
