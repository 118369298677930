const FAKE_BASE = 'http://local';

export default class URLBuilder extends URL {
  constructor(
    url: string,
    urlQuerySearchParams?: URLSearchParams | null | undefined,
    urlHash?: string | null | undefined,
  ) {
    super(url, FAKE_BASE);

    if (urlQuerySearchParams != null) {
      this.updateSearchParams(urlQuerySearchParams);
    }
    if (urlHash != null) {
      this.updateHash(urlHash);
    }
  }

  build(): string {
    return this.replaceFageBase();
  }

  get href(): string {
    return this.build();
  }

  toString(): string {
    return this.build();
  }

  updateSearchParams(searchParams: URLSearchParams | string | null | undefined): void {
    if (typeof searchParams === 'string') {
      this.search = searchParams;
    } else if (searchParams instanceof URLSearchParams) {
      this.search = searchParams.toString();
    } else {
      this.search = '';
    }
  }

  updateHash(hash: string | null | undefined): void {
    if (typeof hash === 'string') {
      this.hash = hash;
    } else {
      this.hash = '';
    }
  }

  private replaceFageBase(): string {
    return super.toString().replace(FAKE_BASE, '');
  }
}
