import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ProductCard } from '@sprinx-app/ui-commerce';
import Link from 'next/link';
import { Fragment, useCallback, useState } from 'react';
import contentProductDetailUrl from '@/content/content-product-detail-url';
import productImageLoader from '@/lib/product-image-loader';
import trpc from '@/lib/trpc';
import type { ProductCardStyledProduct } from './product-card-styled';

export default function LayoutBaseHeaderSearchDialog({
  closeModal,
  isOpen,
}: {
  closeModal: () => void;
  isOpen: boolean;
}) {
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-white/25 backdrop-blur-sm' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-start justify-center p-4 pt-6 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              {/* <Dialog.Panel className='border-surface-suspend bg-surface w-full max-w-md overflow-hidden rounded-2xl border p-6 text-left align-middle transition-all'> */}
              <Dialog.Panel className='flex w-full max-w-2xl flex-col justify-center overflow-hidden p-6 transition-all'>
                <Searcher closeModal={closeModal} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function Searcher({ closeModal }: { closeModal: () => void }) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<{
    firstSearchedProducts: ProductCardStyledProduct[];
    numberOfResults: number;
  } | null>(null);
  const performSearchMutation = trpc.search.products.useMutation();
  const performSearch = useCallback(
    (searchQuery: string) => {
      performSearchMutation.mutate(
        {
          currency: 'CZK',
          limit: 2000, // limit of result in firstSearchedProducts
          locale: 'cs',
          queryString: searchQuery,
          region: 'CZ',
        },
        {
          onError: () => {
            // eslint-disable-next-line no-alert
            alert('SEARCH ERROR...');
          },
          onSuccess: (data) => {
            setSearchResults(data);
          },
        },
      );
    },
    [performSearchMutation],
  );
  const updateSearchQuery = useCallback(
    (value: string): void => {
      // For searchQuery longer then 3+ chars, perform search...
      if (value.length >= 3) {
        performSearch(value);
      }
      // Update searchQuery state
      setSearchQuery(value);
    },
    [performSearch],
  );

  return (
    <>
      <SearchInputBlock searchQuery={searchQuery} updateSearchQuery={updateSearchQuery} />
      {searchResults != null && (
        <div
          className='border-surface-divid bg-surface mt-6 w-full rounded-md border p-6 text-left shadow-lg'
          style={{
            height: '43em',
            overflowY: 'scroll',
          }}
        >
          {searchResults.firstSearchedProducts.length === 0 ? (
            <p>{'Nic se nenašlo'}</p>
          ) : (
            <SearchResultsBlock
              closeModal={closeModal}
              firstSearchedProducts={searchResults.firstSearchedProducts}
              numberOfResults={searchResults.numberOfResults}
            />
          )}
        </div>
      )}
    </>
  );
}

function SearchInputBlock({
  searchQuery,
  updateSearchQuery,
}: {
  searchQuery: string;
  updateSearchQuery: (value: string) => void;
}) {
  return (
    <div className='form-styles flex justify-center'>
      <div className='bg-surface w-full max-w-md rounded shadow-lg'>
        <label className='sr-only' htmlFor='global-search-query'>
          {'Zadejte hlednaný výraz'}
        </label>
        <div className='relative' data-role='input-group'>
          <div data-role='adornment-start'>
            <MagnifyingGlassIcon aria-hidden='true' className='text-content-suspend h-5 w-5' />
          </div>
          <input
            aria-describedby='global-search-query-description'
            autoComplete='off'
            className='!pl-10'
            id='global-search-query'
            onChange={(e) => {
              const nextValue = e.target.value;
              updateSearchQuery(nextValue);
            }}
            placeholder='Zadejte hlednaný výraz'
            type='text'
            value={searchQuery}
          />
        </div>
        {/*
            <p className='!mt-2 mb-1 px-2 text-left' id='global-search-query-description' role='tooltip'>
              Zadejte znaky a bude se hledat...
            </p>
          */}
      </div>
    </div>
  );
}

function SearchResultsBlock({
  closeModal,
  firstSearchedProducts,
  numberOfResults,
}: {
  closeModal: () => void;
  firstSearchedProducts: ProductCardStyledProduct[];
  numberOfResults: number;
}) {
  return (
    <>
      <p className='text-center text-sm sm:text-base'>{`Zobrazeno ${firstSearchedProducts.length} z ${numberOfResults} výsledků`}</p>
      <ul className='divide-surface-divid divide-y'>
        {firstSearchedProducts.map((item) => (
          <li key={item.id}>
            <article className='hover:bg-surface-suspend hover:text-content-focus relative my-2 inline-flex w-full items-center gap-4 rounded p-4'>
              <ProductCard.Image
                alt={item.primaryImage?.alt ?? item.name}
                className='bg-surface aspect-[4/3] h-14 p-2 group-hover:opacity-75'
                height={item.primaryImage?.height ?? 40}
                imageClassName='h-full w-full object-contain object-center'
                loader={productImageLoader}
                src={item.primaryImage?.src}
                unoptimized
                width={item.primaryImage?.width ?? 120}
              />
              <h1>{item.name}</h1>
              <Link
                href={contentProductDetailUrl(item.sku, item.name)}
                onClick={() => {
                  closeModal();
                }}
                prefetch={false}
              >
                <span className='absolute inset-0' />
              </Link>
            </article>
          </li>
        ))}
      </ul>
    </>
  );
}
