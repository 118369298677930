import Link from 'next/link';
import contentAboutUsUrl from '@/content/content-about-us-url';
import contentContactsUrl from '@/content/content-contacts-url';
import classNames from '@/lib/class-names';
import type { HTMLAttributeAnchorTarget, ReactNode } from 'react';

export default function LayoutBaseHeaderNavigationTopStrip() {
  return (
    <div className='bg-black print:hidden'>
      <div className='mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8'>
        <div className='hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6'>
          <LayoutBaseHeaderNavigationTopStripLink href={contentAboutUsUrl()}>
            {'O nás'}
          </LayoutBaseHeaderNavigationTopStripLink>
          <span aria-hidden='true' className='bg-surface-divid h-6 w-px' />
          <LayoutBaseHeaderNavigationTopStripLink href={contentContactsUrl()}>
            {'Kontakty'}
          </LayoutBaseHeaderNavigationTopStripLink>
          <span aria-hidden='true' className='bg-surface-divid h-6 w-px' />
          <LayoutBaseHeaderNavigationTopStripLink emphasize href={'https://www.bunzl.com/'} target={'_blank'}>
            {'BUNZL Green'}
          </LayoutBaseHeaderNavigationTopStripLink>
          <span aria-hidden='true' className='bg-surface-divid h-6 w-px' />
          <LayoutBaseHeaderNavigationTopStripLink href={'https://b2b.blyth.cz/prihlaseni'} target={'_blank'}>
            {'B2B eshop'}
          </LayoutBaseHeaderNavigationTopStripLink>
        </div>
      </div>
    </div>
  );
}

// *****************************************
// *
// * LayoutBaseHeaderNavigationTopStripLink
// *
// *****************************************

function LayoutBaseHeaderNavigationTopStripLink({
  children,
  emphasize = false,
  href,
  prefetch = false,
  shallow = false,
  target,
}: {
  children: ReactNode;
  emphasize?: boolean;
  href: string;
  prefetch?: boolean;
  shallow?: boolean;
  target?: HTMLAttributeAnchorTarget;
}) {
  return (
    <Link
      className={classNames(
        ' text-sm font-medium',
        emphasize === false && 'text-content-contrast hover:text-surface-suspend',
        emphasize === true && 'text-secondary hover:text-secondary-focus',
      )}
      href={href}
      prefetch={prefetch}
      shallow={shallow}
      target={target}
    >
      {children}
    </Link>
  );
}
