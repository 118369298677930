import kebabCase from 'lodash/kebabCase';
import invariant from 'tiny-invariant';
import URLBuilder from '@/lib/url-builder';

export default function contentProductDetailUrl(
  productSku: string,
  productName?: string,
  urlQuerySearchParams?: URLSearchParams | null | undefined,
  urlHash?: string | null | undefined,
): string {
  invariant(typeof productSku === 'string', `'contentProductDetailUrl' requires 'sku' parameter to be defined.`);
  const encodedSku = encodeURIComponent(productSku);
  const encodedName = kebabCase(productName ?? 'produkt');
  const url = new URLBuilder(`/${encodedName}/p/${encodedSku}`, urlQuerySearchParams, urlHash);
  return url.build();
}
